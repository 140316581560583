import React from 'react';

import Client from 'src/pages/Client';
import Clients from 'src/pages/Clients';

export enum RouteNamesENUM {
  LOGIN = '/',
  CLIENTS = '/',
  CLIENT = 'client/:id',
}

export interface IRoute {
    path: string;
    element: React.ReactNode;
}

export const privateRoutes: IRoute[] = [
  { path: RouteNamesENUM.CLIENTS, element: <Clients /> },
  { path: RouteNamesENUM.CLIENT, element: <Client /> },
];
