/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { message } from 'antd';

import Header from 'src/components/Header/Header';
import ClientList from './components/ClientList/ClientList';
import AddEditForm from './components/AddEditForm/AddEditForm';
import Loader from 'src/components/Loader/Loader';

import companyApi from 'src/api/companyApi';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { companiesSliceActions } from 'src/store/companiesSlice/companiesSlice.reducer';
import { toastMessages } from 'src/utils/constants';
import { checkError } from 'src/utils/checkError';
import { collator } from 'src/utils/dataHelper';

import styles from './clients.module.scss';

const Clients: React.FC = () => {
  const {
    currentId,
    modeType,
    isShowAddForm,
  } = useAppSelector((state) => state.companies);

  const [isloading, setIsLoading] = React.useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const errorItem = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.companyAlreadyExists,
    });
  };

  useEffect(() => {
    if (currentId || isShowAddForm) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [currentId, isShowAddForm]);

  const error = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.somethingWentWrong,
    });
  };

  const errorNoCompany = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.noCompany,
    });
  };

  const checkedError = (err: any) => {
    const isBadGatewayError = checkError.isBadGatewayError(err);
    if (isBadGatewayError) {
      error();
    } else if (
      err?.code === 'ERR_BAD_REQUEST' &&
      (err?.response?.data?.message?.includes('already exists') || err?.response?.data?.message?.includes('already in use'))
    ) {
      errorItem();
    } else if (err?.code === 'ERR_BAD_REQUEST' && err?.response?.data?.message?.includes('not find company')) {
      errorNoCompany();
      loadClients();
    }
  };

  useEffect(() => {
    loadClients();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();

  const loadClients = async () => {
    try {
      setIsLoading(true);
      const response = await companyApi.getCompanies();

      const sortedCompanies = response.data.companies.sort(
        (a, b) => collator.compare(a.name, b.name),
      );
      dispatch(companiesSliceActions.setCompanies({
        companies: sortedCompanies,
        count: response.data.count,
        page: 1,
        perPage: response.data.count,
      }));
    } catch (err) {
      console.error('load error', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseForm = () => {
    dispatch(companiesSliceActions.setCurrentId(0));
    dispatch(companiesSliceActions.setModeType('add'));
    dispatch(companiesSliceActions.setIsShowAddForm(false));
  };

  return (
    <>
      <div className={styles['clients-layout-container']}>
        <Header />
        <ClientList onErr={checkedError} />
      </div>
      {currentId || isShowAddForm
        ? (<AddEditForm
            onErr={checkedError}
            id={currentId}
            type={modeType}
            cb={handleCloseForm}
          />)
        : null}

      {isloading && <Loader />}
      {contextHolder}
    </>
  );
};

export default Clients;
