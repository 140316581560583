import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { IPoint } from 'src/types';

const getInitialStore = () => ({
  points: [] as IPoint[],
  currentId: 0,
  companyName: '',
  modeType: 'add' as 'add' | 'edit',
  isShowAddForm: false,
  playList: [] as {
    folder: string;
    isActual: boolean;
  }[],
});

const pointsSlice = createSlice({
  name: 'points',
  initialState: getInitialStore,
  reducers: {
    setPoints: (store, { payload }: PayloadAction<IPoint[]>) => {
      store.points = payload;
    },
    updatePoints: (
      store,
      { payload }: PayloadAction<{
        id: number;
        data: IPoint;
      }>,
    ) => {
      const updatedArray = store.points.map((point) => (
        point.id !== payload.id ? point : payload.data
      ));
      store.points = updatedArray;
    },
    createPoints: (store, { payload }: PayloadAction<IPoint>) => {
      store.points = [...store.points, payload];
    },
    deletePoints: (store, { payload }: PayloadAction<number>) => {
      store.points = store.points.filter((points) => points.id !== payload);
    },
    setCurrentId: (store, { payload }: PayloadAction<number>) => {
      store.currentId = payload;
    },
    setModeType: (store, { payload }: PayloadAction<'add' | 'edit'>) => {
      store.modeType = payload;
    },
    setIsShowAddForm: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowAddForm = payload;
    },
    setCompanyName: (store, { payload }: PayloadAction<string>) => {
      store.companyName = payload;
    },
    setPlayList: (store, { payload }: PayloadAction<{
      folder: string;
      isActual: boolean;
    }[]>) => {
      store.playList = payload;
    },
    resetpoints: (store) => {
      store.points = [];
      store.currentId = 0;
      store.modeType = 'add';
      store.isShowAddForm = false;
      store.companyName = '';
      store.playList = [];
    },
  },
});

export const pointsSliceActions = pointsSlice.actions;

export default pointsSlice.reducer;
