import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { ICompaniesResponse, ICompany } from 'src/types';

const getInitialStore = () => ({
  companies: [] as ICompany[],
  count: 0,
  page: 1,
  perPage: 5,
  currentId: 0,
  modeType: 'add' as 'add' | 'edit',
  isShowAddForm: false,
});

const companiesSlice = createSlice({
  name: 'companies',
  initialState: getInitialStore,
  reducers: {
    setCompanies: (store, { payload }: PayloadAction<ICompaniesResponse>) => {
      store.companies = payload.companies;
      store.count = payload.count;
      store.page = payload.page;
    },
    updateCompany: (store, { payload }: PayloadAction<{ id: number; data: string }>) => {
      const res = store.companies.find((category) => category.id === payload.id);
      if (res && res.name) {
        res.name = payload.data;
      }
    },
    createCompany: (store, { payload }: PayloadAction<ICompany>) => {
      store.companies = [...store.companies, payload];
    },
    deleteCompany: (store, { payload }: PayloadAction<number>) => {
      store.companies = store.companies.filter((company) => company.id !== payload);
    },
    setCurrentId: (store, { payload }: PayloadAction<number>) => {
      store.currentId = payload;
    },
    setModeType: (store, { payload }: PayloadAction<'add' | 'edit'>) => {
      store.modeType = payload;
    },
    setIsShowAddForm: (store, { payload }: PayloadAction<boolean>) => {
      store.isShowAddForm = payload;
    },
    setPage: (store, { payload }: PayloadAction<number>) => {
      store.page = payload;
    },
    resetCompanies: (store) => {
      store.companies = [];
      store.count = 0;
      store.page = 1;
      store.perPage = 5;
      store.currentId = 0;
      store.modeType = 'add';
      store.isShowAddForm = false;
    },
  },
});

export const companiesSliceActions = companiesSlice.actions;

export default companiesSlice.reducer;
