/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, Popconfirm, Typography, Input } from 'antd';

import { ReactComponent as Trash } from 'src/assets/trash.svg';
import { ReactComponent as Edit } from 'src/assets/edit.svg';

import { useAppDispatch, useAppSelector } from 'src/store/store';
import { companiesSliceActions } from 'src/store/companiesSlice/companiesSlice.reducer';
import { pointsSliceActions } from 'src/store/pointsSlice/pointsSlice.reducer';

import styles from './clientList.module.scss';
import companyApi from 'src/api/companyApi';

type ClientListPropsType = {
  onErr: (err: any) => void;
};

const ClientList: React.FC<ClientListPropsType> = ({ onErr }) => {
  const { Paragraph } = Typography;

  const { companies, count } = useAppSelector((state) => state.companies);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [filterInput, setFilterInput] = useState('');

  const goToPoints = (id: number, name: string) => {
    dispatch(pointsSliceActions.resetpoints());
    dispatch(pointsSliceActions.setCompanyName(name));
    dispatch(companiesSliceActions.setCurrentId(0));
    navigate(`/client/${id}`);
  };

  const handleAdd = () => {
    dispatch(companiesSliceActions.setModeType('add'));
    dispatch(companiesSliceActions.setIsShowAddForm(true));
  };

  const handleEdit = (id: number) => {
    dispatch(companiesSliceActions.setModeType('edit'));
    dispatch(companiesSliceActions.setIsShowAddForm(false));
    dispatch(companiesSliceActions.setCurrentId(id));
  };

  const handleTrash = async (id: number) => {
    try {
      await companyApi.deleteCompany(id);
      const sortedCompanies = companies.filter((company) => company.id !== id);
      dispatch(companiesSliceActions.setCompanies({
        companies: sortedCompanies,
        count: sortedCompanies.length,
        page: 1,
        perPage: sortedCompanies.length,
      }));
    } catch (err) {
      console.error(err);
      onErr(err);
    }
  };

  const Clients = useMemo(() => {
    return companies.filter(
      (company) => company.name.toLowerCase().includes(filterInput.toLowerCase()),
    ).map((company) => {
      return (
        <div id={`company-${company.id}`} className={styles['client-card']} key={company.id}>
          <div
            className={styles['client-card_content_container']}
          >
          <div className={styles['client-card_name']}>
            <Paragraph className={styles.companyText}>
              {company.name}
            </Paragraph>

            <Paragraph className={styles.clientPoints}>
              Количество точек: {company.clientsCount ?? 0}
            </Paragraph>
          </div>
          <div className={styles['client-registration-text']}>
            <Paragraph className={styles.registrationDate}>
              Регистрация: {moment(company.created_at, 'YYYY-MM-DDTHH:mm:ss.SSSZ').local().format('DD.MM.YYYY') ?? ''}
            </Paragraph>

            <Divider type="vertical" className={styles.dividerContainer} />

            <Button
              type="link"
              icon={<Edit />}
              size="large"
              onClick={() => handleEdit(company.id ?? 0)}
            />

            <Divider type="vertical" className={styles.dividerContainer} />

            <Popconfirm
              placement="top"
              title="Удалить пользователя?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => handleTrash(company.id ?? 0)}
            >

              <Button
                type="link"
                icon={<Trash />}
                size="large"
              />
            </Popconfirm>
          </div>
          </div>
          <div
            className={styles.action_item}
            onClick={() => goToPoints(company.id ?? 0, company.name)}
          />
        </div>
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, filterInput]);

  return (
    <div className={styles['client-list-container']}>
      <div className={styles['client-list_controls']}>
        <Paragraph className={styles.clientsTitle}>
          {'Клиенты: '}
          <span className={styles.clientsTitleNumber}>{count}</span>
        </Paragraph>

        <Button
          type="primary"
          className={styles.addClientButton}
          size="large"
          onClick={handleAdd}
        >
          Добавить клиента
        </Button>
      </div>

      <Input
        placeholder="Поиск по наименованию"
        value={filterInput}
        onChange={(e) => setFilterInput(e.target.value)}
        className={styles.searchBar}
      />

      {Clients}
    </div>
  );
};

export default ClientList;
