import type { ICompany, IPoint } from 'src/types';

export const collator = new Intl.Collator('ru', { sensitivity: 'accent' });
export const compareByName = (a: { name: string }, b: { name: string }) => {
  if (collator.compare(a.name.toLowerCase(), b.name.toLowerCase()) === -1) {
    return -1;
  }
  if (collator.compare(a.name.toLowerCase(), b.name.toLowerCase()) === 1) {
    return 1;
  }
  return 0;
};

export const getSortedPoints = (
  points: IPoint[],
  currentPoint: IPoint,
  currentId?: number,
) => {
  const newPoints = currentId
    ? [...points.filter((point) => point.id !== currentId)]
    : [...points];
  let left = 0;
  let right = newPoints.length - 1;
  let insertIndex = 0;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (compareByName(newPoints[mid], currentPoint) === 1) {
      insertIndex = mid;
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  newPoints.splice(insertIndex, 0, currentPoint);

  return newPoints;
};

export const getSortedCompanies = (
  companies: ICompany[],
  currentCompany: ICompany,
  companyId?: number,
) => {
  const newCompanies = companyId
    ? [...companies.filter((company) => company.id !== companyId)]
    : [...companies];
  let left = 0;
  let right = newCompanies.length - 1;
  let insertIndex = 0;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);

    if (compareByName(newCompanies[mid], currentCompany) === 1) {
      insertIndex = mid;
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  newCompanies.splice(insertIndex, 0, currentCompany);

  return newCompanies;
};
