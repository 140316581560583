import React from 'react';

import { Spin } from 'antd';

import styles from './loader.module.scss';

const Loader: React.FC = () => {
  return (
    <div className={styles.loaderContainer}>
      <Spin size="large" />
    </div>
  );
};

export default Loader;
