import React, { useEffect, useMemo } from 'react';
import { ConfigProvider } from 'antd';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Login from './pages/auth/Login';

import { privateRoutes, RouteNamesENUM } from './router';
import storage from './utils/storage';
import { useAppDispatch, useAppSelector } from './store/store';
import mainSliceThunks from './store/mainSlice/mainSlice.thunks';

import COLOR from './styles/colors';

import './App.scss';
import Loader from './components/Loader/Loader';

const App: React.FC = () => {
  const user = useAppSelector((state) => state.main.user);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const token = storage.authToken.get();

  const isAuthorized = useMemo(() => {
    return !!token && !!user;
  }, [token, user]);

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [token, navigate]);

  useEffect(() => {
    if (!token) {
      return;
    }
    dispatch(mainSliceThunks.authorize());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = useMemo(() => !!token && !user, [token, user]);

  if (isLoading) {
    return <Loader />;
  }

  return (
      <ConfigProvider theme={{ token: { colorPrimary: COLOR.PRIMARY } }}>
        <Routes>
        {isAuthorized
          ? (

            privateRoutes.map((route) => (
                <Route
                  path={route.path}
                  element={route.element}
                  key={route.path}
              />))

          )

          : (
              <Route path={RouteNamesENUM.LOGIN} element={<Login />} />
          )}
        </Routes>
      </ConfigProvider>
  );
};

export default App;
