/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';

import {
  Button,
  Form,
  type FormProps,
  Input,
  Typography,
  DatePicker,
  Dropdown,
  Menu,
} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { CloseOutlined } from '@ant-design/icons';

import companyPointApi from 'src/api/companyPointApi';
import { getSortedPoints } from 'src/utils/dataHelper';
import { pointsSliceActions } from 'src/store/pointsSlice/pointsSlice.reducer';
import { useAppDispatch, useAppSelector } from 'src/store/store';

import styles from './addEditForm.module.scss';
import moment from 'moment';

type FieldType = {
  name?: string;
  expiresAt: string;
  playlistPath?: string;
};

type AddEditFormPropsType = {
  type: 'add' | 'edit';
  id: number;
  cb: () => void;
  onErr: (err: any) => void;
};

const dateFormat = 'DD-MM-YYYY';

dayjs.extend(customParseFormat);
dayjs.locale('ru');

const AddEditForm: React.FC<AddEditFormPropsType> = ({ type, cb, onErr }) => {
  const [form] = Form.useForm();
  const { points, currentId, playList } = useAppSelector((state) => state.points);
  const [disabled, setDisabled] = React.useState(false);

  const { id: currentCompanyId } = useParams();

  const currentPoint = useMemo(() => {
    if (type === 'add') {
      return {
        name: '',
        expiresAt: '',
        playlistPath: '',
      };
    }
    const current = points.find((point) => point.id === currentId);
    if (!current) {
      return {
        name: '',
        expiresAt: '',
        playlistPath: '',
      };
    }
    return {
      name: current?.name,
      expiresAt: current?.expiresAt,
      playlistPath: current?.playlistPath,
    };
  }, [type, points, currentId]);

  const [inputValue, setInputValue] = React.useState<string>(currentPoint.playlistPath ?? '');

  const dispatch = useAppDispatch();
  const { Title } = Typography;
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      setDisabled(true);
      if (type === 'add') {
        const response = await companyPointApi.createCompanyPoint({
          name: values.name ?? '',
          expiresAt: values.expiresAt ?? '',
          playlistPath: inputValue,
        }, Number(currentCompanyId));

        if (response.data) {
          const sortedPoints = getSortedPoints(points, response.data);
          dispatch(pointsSliceActions.setPoints(sortedPoints));

          setTimeout(() => {
            const pointElement = document.getElementById(`point-${response.data.id}`);
            if (pointElement) {
              pointElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 300);
        }
      } else {
        const localeOffset = dayjs().utcOffset();
        const response = await companyPointApi.updateCompanyPoint(Number(currentId), {
          name: values.name ?? '',
          expiresAt: values.expiresAt ? moment(new Date(values.expiresAt)).add(localeOffset / 60, 'hours').format() : '',
          playlistPath: inputValue,
          companyId: Number(currentCompanyId),
        });

        if (response.data) {
          const sortedPoints = getSortedPoints(points, response.data, currentId);
          dispatch(pointsSliceActions.setPoints(sortedPoints));
        }
      }
    } catch (err) {
      onErr(err);
    } finally {
      cb();
      setDisabled(false);
    }
  };

  const onSelectedOption = (e: { key: string }) => {
    setInputValue(e.key);
    form.setFieldsValue({ playlistPath: e.key });
  };

  const menu = useMemo(() => (
    <Menu>
      {playList.map((option) => (
        <Menu.Item
          key={option.folder}
          onClick={(e) => onSelectedOption(e)}
        >
          {option.isActual
            ? option.folder
            : (<div className={styles.folderContainer}>
                {option.folder}
                <div className={styles.dot} />
               </div>)
          }
        </Menu.Item>
      ))}
    </Menu>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [playList]);

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className={styles['add-edit-form']}>
      <Form
        form={form}
        name="basic"
        className={styles.formContainer}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          name: currentPoint.name,
          playlistPath: currentPoint.playlistPath,
          expiresAt: type === 'add' ? dayjs() : dayjs(currentPoint.expiresAt ?? '', 'YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }}
      >
        <div className={styles.closeIconContainer}>
          <Button
            size="large"
            className={styles.closeIcon}
            onClick={cb}
            icon={<CloseOutlined />}
          />
        </div>

        <Title className={styles.addEditFormTitle}>
          {type === 'add' ? 'Добавить точку' : 'Редактировать точку'}
        </Title>
          <Form.Item<FieldType>
            name="name"
            rules={[{ required: true, message: 'Поле обязательно' }]}
            className={styles.inputContainer}
          >

            <Input
              size="large"
              placeholder="Адрес"
              className={styles.inputName}
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="expiresAt"
            rules={[{ required: true, message: 'Поле обязательно' }]}
            className={styles.inputContainer}
          >
            <DatePicker
              placeholder="Дата окончания"
              className={styles.dateInput}
              defaultValue={type === 'add' ? dayjs() : dayjs(currentPoint.expiresAt ?? '', 'YYYY-MM-DDTHH:mm:ss.SSSZ')}
              format={dateFormat}
              locale={locale}
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="playlistPath"
            rules={[{ required: true, message: 'Поле обязательно' }]}
            className={styles.inputContainer}
            required
          >
            <Dropdown overlay={menu} trigger={['click']}>
              <Input
                size="large"
                placeholder="Название плейлиста"
                className={styles.inputName}
                value={inputValue}
              />
            </Dropdown>
          </Form.Item>

          <Form.Item className={styles.buttonContainer}>
            <Button disabled={disabled} type="primary" htmlType="submit" className={styles.addButton} size="large">
              {type === 'add' ? 'Добавить' : 'Сохранить'}
            </Button>
          </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditForm;
