/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import {
  Button,
  Form,
  type FormProps,
  Input,
  Typography,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import companyApi from 'src/api/companyApi';
import { companiesSliceActions } from 'src/store/companiesSlice/companiesSlice.reducer';
import { useAppDispatch, useAppSelector } from 'src/store/store';

import styles from './addEditForm.module.scss';
import { getSortedCompanies } from 'src/utils/dataHelper';

type FieldType = {
  name?: string;
};

type AddEditFormPropsType = {
  type: 'add' | 'edit';
  id: number;
  cb: () => void;
  onErr: (err: any) => void;
};

const AddEditForm: React.FC<AddEditFormPropsType> = ({ type, id, cb, onErr }) => {
  const { companies, count, page, perPage } = useAppSelector((state) => state.companies);

  const [disabled, setDisabled] = React.useState(false);

  const currentCompany = useMemo(() => {
    if (type === 'add') {
      return '';
    }
    return companies.find((company) => company.id === id)?.name ?? '';
  }, [type, id, companies]);

  const dispatch = useAppDispatch();
  const { Title } = Typography;
  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      setDisabled(true);
      if (type === 'add') {
        const response = await companyApi.createCompanies({
          name: values.name ?? '',
        });
        if (response.data) {
          const sortedCompanies = getSortedCompanies(companies, response.data);
          dispatch(companiesSliceActions.setCompanies({
            companies: sortedCompanies,
            count: sortedCompanies.length,
            page,
            perPage,
          }));

          setTimeout(() => {
            const companyElement = document.getElementById(`company-${response.data.id}`);
            if (companyElement) {
              companyElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 300);
        }
      } else {
        const response = await companyApi.updateCompany({
          name: values.name ?? '',
        }, id);
        const sortedCompanies = getSortedCompanies(companies, response.data, id);
        dispatch(companiesSliceActions.setCompanies({
          companies: sortedCompanies,
          count,
          page,
          perPage,
        }));
      }
    } catch (err) {
      onErr(err);
    } finally {
      cb();
      setDisabled(false);
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className={styles['add-edit-form']}>
      <Form
        name="basic"
        className={styles.formContainer}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          name: currentCompany,
        }}
      >
        <div className={styles.closeIconContainer}>
          <Button
            size="large"
            className={styles.closeIcon}
            onClick={cb}
            icon={<CloseOutlined />}
          />
        </div>

        <Title className={styles.addEditFormTitle}>
          {type === 'add' ? 'Добавление пользователя' : 'Редактирование пользователя'}
        </Title>
          <Form.Item<FieldType>
            name="name"
            rules={[{ required: true, message: 'Поле обязательно' }]}
            className={styles.inputContainer}
          >

            <Input
              size="large"
              placeholder="Имя пользователя"
              className={styles.inputName}
            />
          </Form.Item>

          <Form.Item className={styles.buttonContainer}>
            <Button disabled={disabled} type="primary" htmlType="submit" className={styles.addButton} size="large">
              {type === 'add' ? 'Добавить' : 'Сохранить'}
            </Button>
          </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditForm;
