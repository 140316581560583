export enum EnvTypeENUM {
  development = 'development',
  production = 'production',
}

export const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || EnvTypeENUM.development;

export const isDev = envType === EnvTypeENUM.development;
export const isProd = envType === EnvTypeENUM.production;

const config = {
  apiUrl: 'http://szmusic.ru:82/',
  maxNumberOfAttempts: 10,
  protocol: 'http://',
  domain: 'localhost:3000',
};

// eslint-disable-next-line default-case
switch (envType) {
  case EnvTypeENUM.development:
    config.apiUrl = 'http://176.113.80.172:3334/';
    config.protocol = 'http://';
    config.domain = 'http://dev..com/';
    break;
  case EnvTypeENUM.production:
    config.apiUrl = 'http://szmusic.ru:82/';
    config.protocol = 'http://';
    config.domain = 'http://dev..com/';
    break;
}

export default config;
