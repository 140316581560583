export const toastMessages = {
  error: {
    networkError: 'Что-то пошло не так',
    somethingWentWrong: 'Что-то пошло не так',
    pointAlreadyExists: 'Точка с таким адресом уже существует',
    companyAlreadyExists: 'Клиент с таким именем уже существует',
    passwordIncorrect: 'Неверный пароль',
    noCompany: 'Клиент был удален',
    noPoint: 'Точка была удалена',
  },
  success: {
    success: 'Successful action',
    Successful: 'Successful',
  },
  warn: {},
};

export const maxFileSize = 2 * 1024 * 1024; // 2MB
