import { combineReducers } from 'redux';

import mainSlice from 'src/store/mainSlice';
import companiesSlice from 'src/store/companiesSlice';
import pointsSlice from 'src/store/pointsSlice';

const combinedReducer = combineReducers({
  main: mainSlice,
  companies: companiesSlice,
  points: pointsSlice,
});

export const RESET_STORE_ACTION_TYPE = 'RESET_WHOLE_STORE';

const rootReducer = (
  state: ReturnType<typeof combinedReducer> | undefined,
  action: { type: string; payload: unknown },
) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export default rootReducer;
