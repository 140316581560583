import type { IPoint } from 'src/types';
import http from './http';

import type { ResponseType } from './http';

type ShortPointDescriptionType = {
  name?: string;
  playlistPath?: string;
  expiresAt?: string;
  companyId?: number;
};

const createCompanyPoint = async (data: ShortPointDescriptionType, companyId: number) => {
  const response = await http.post<ResponseType<IPoint>>(`/users/${companyId}`, data);
  return response;
};

const getCompanyPoints = async () => {
  const response = await http.get<ResponseType<IPoint[]>>('/users');
  return response;
};

const getPointKey = async (pointId: number) => {
  const response = await http.get<ResponseType<string>>(`/users/generate-code/${pointId}`);
  return response;
};
const switchPointActive = async (pointId: number) => {
  const response = await http.get<ResponseType<IPoint>>(`/users/switch-active/${pointId}`);
  return response;
};

const updateCompanyPoint = async (id: number, data: ShortPointDescriptionType) => {
  const response = await http.patch<ResponseType<IPoint>>(`/users/${id}`, data);
  return response;
};

const deleteCompanyPoint = async (id: number) => {
  const response = await http.delete<ResponseType<IPoint>>(`/users/${id}`);
  return response;
};

const getPlaylists = async () => {
  const response = await http.get<ResponseType< {
    folder: string;
    isActual: boolean;
  }[]>>('/station/playlists-paths');
  return response;
};

export default {
  createCompanyPoint,
  getCompanyPoints,
  getPointKey,
  updateCompanyPoint,
  deleteCompanyPoint,
  switchPointActive,
  getPlaylists,
};
