/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Button, Form, type FormProps, Input, message } from 'antd';
import { Typography } from 'antd';

import { ReactComponent as StudioZv } from 'src/assets/logo.svg';

import { useAppDispatch } from 'src/store/store';
import { mainSliceActions } from 'src/store/mainSlice/mainSlice.reducer';
import authApi from 'src/api/authApi';

import { checkError } from 'src/utils/checkError';
import { toastMessages } from 'src/utils/constants';

import styles from './login.module.scss';

type FieldType = {
  password?: string;
};

const { Title, Paragraph } = Typography;

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  const [messageApi, contextHolder] = message.useMessage();

  const errorPass = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.passwordIncorrect,
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.somethingWentWrong,
    });
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
    try {
      await authApi.login({
        login: 'admin',
        password: values.password ?? '',
      });
      dispatch(mainSliceActions.setUser({ login: 'admin' }));
    } catch (err: any) {
      const isBadGatewayError = checkError.isBadGatewayError(err);
      if (isBadGatewayError) {
        error();
      } else if (err?.code === 'ERR_BAD_REQUEST') {
        errorPass();
      }
    }
  };

  const onFinishFailed: FormProps<FieldType>['onFinishFailed'] = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };
  return (
    <>
    <div className="main-layout-container">
      <div className="content-container">
        <div className={styles.logo}>
          <StudioZv />
        </div>
        <Paragraph
          className={styles.logoText}
        >
          STUDIOZVUKA
        </Paragraph>

        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={styles.loginForm}
        >
          <Title className={styles.loginTitle}>
            Вход
          </Title>

          <Form.Item<FieldType>
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите пароль' }]}
            className={styles.passwordWrapper}
          >
            <Input.Password size="large" placeholder="Введите пароль" className={styles.passwordInput} />
          </Form.Item>

          <Form.Item className={styles.submitButtonContainer}>
            <Button type="primary" htmlType="submit" className={styles.submitButton} size="large">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    {contextHolder}
    </>
  );
};

export default Login;
