import store, { resetStore } from 'src/store';
import storage from './storage';

const sleep = (timeout: number) => new Promise((res) => { setTimeout(res, timeout); });

const logOut = () => {
  store.dispatch(resetStore());
  storage.authToken.remove();
  window.location.replace('/');
};

const setToken = (authToken: string) => {
  storage.authToken.set(authToken);
};

export default {
  sleep,
  logOut,
  setToken,
};
