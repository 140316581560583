/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { Button, Divider, Popconfirm, Switch, Typography, Tooltip, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { ReactComponent as Trash } from 'src/assets/trash.svg';
import { ReactComponent as Edit } from 'src/assets/edit.svg';
import { ReactComponent as GoBack } from 'src/assets/goBack.svg';

import PointApi from 'src/api/companyPointApi';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { pointsSliceActions } from 'src/store/pointsSlice/pointsSlice.reducer';

import type { IPoint } from 'src/types';

import styles from './clientList.module.scss';

type ClientListPropsType = {
  onErr: (err: any) => void;
};

const ClientList: React.FC<ClientListPropsType> = ({ onErr }) => {
  const { Paragraph } = Typography;

  const { points, companyName, playList } = useAppSelector((state) => state.points);

  const [code, setCode] = useState('');
  const [codeId, setCodeId] = useState(0);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [filterInput, setFilterInput] = useState('');

  const goToClients = () => {
    dispatch(pointsSliceActions.setCurrentId(0));
    navigate('/');
  };

  const handleAdd = () => {
    dispatch(pointsSliceActions.setModeType('add'));
    dispatch(pointsSliceActions.setIsShowAddForm(true));
  };

  const handleEdit = (id: number) => {
    dispatch(pointsSliceActions.setModeType('edit'));
    dispatch(pointsSliceActions.setIsShowAddForm(false));
    dispatch(pointsSliceActions.setCurrentId(id));
  };

  const handleTrash = async (id: number) => {
    try {
      const response = await PointApi.deleteCompanyPoint(id);
      if (response.data) {
        const sortedPoints = points.filter((point) => point.id !== id);
        dispatch(pointsSliceActions.setPoints(sortedPoints));
      }
    } catch (err) {
      console.error(err);
      onErr(err);
    }
  };

  const [loadingSwitch, setLoadingSwitch] = useState(-1);

  const onSwitchPressed = async (id: number, point: IPoint) => {
    try {
      setLoadingSwitch(id);
      dispatch(pointsSliceActions.updatePoints({
        id,
        data: {
          ...point,
          active: !point.active,
        } }));
      const response = await PointApi.switchPointActive(id);
      dispatch(pointsSliceActions.updatePoints({
        id,
        data: {
          ...point,
          active: response.data.active,
        } }));
    } catch (err) {
      onErr(err);
      dispatch(pointsSliceActions.updatePoints({
        id,
        data: {
          ...point,
          active: point.active,
        } }));
    } finally {
      setLoadingSwitch(-1);
    }
  };

  const getCodeHandler = async (id: number, point: IPoint) => {
    try {
      const response = await PointApi.getPointKey(id);
      setCodeId(id);
      setCode(response.data);
      dispatch(pointsSliceActions.updatePoints({
        id,
        data: {
          ...point,
          hasCode: true,
        } }));
    } catch (err) {
      console.error(err);
      onErr(err);
    }
  };

  const Clients = useMemo(() => {
    return points.filter(
      (point) => point.name.toLowerCase().includes(filterInput.toLowerCase()),
    ).map((Point) => {
      const isDisabled = moment(Point.expiresAt).local().startOf('day').isBefore(moment().local().startOf('day'));
      let isActual = true;
      if (playList.length) {
        const currentPlaylist = playList.find((playlist) => playlist.folder === Point.playlistPath);
        if (currentPlaylist) {
          isActual = currentPlaylist.isActual;
        } else {
          isActual = false;
        }
      }

      const SwitchComponent = (
        <Switch
          disabled={isDisabled}
          loading={Point.id === loadingSwitch}
          value={Point.active ?? false}
          onChange={() => {
            onSwitchPressed(Point.id ?? 0, Point);
          }}
        />
      );

      const ComponentToRender = isDisabled ? (
        <Tooltip title="Срок действия лицензии истек">
          {SwitchComponent}
        </Tooltip>
      ) : (
        SwitchComponent
      );

      return (
        <div id={`point-${Point.id}`} className={styles['client-card']} key={Point.id}>
          <div
            className={styles['client-card_content_container']}
          >
          <div className={styles['client-card_name']}>
            <div className={styles.pointNameContainer}>
              <Paragraph className={styles.pointText}>
                {Point.name}
              </Paragraph>

              <div className={styles.clientId}>{Point.id ? `(id ${Point.id})` : ''}</div>
            </div>

            <Paragraph className={styles.clientPoints}>
              {isActual
                ? Point.playlistPath ?? ''
                : (<div className={styles.folderContainer}>
                    {Point.playlistPath}
                    <div className={styles.dot} />
                   </div>)}
            </Paragraph>
          </div>

          {codeId === Point.id ? (
          <div className={styles.codeContainer}>
            <Paragraph copyable={{ tooltips: false }} className={styles.code}>{code}</Paragraph>

            <Button
              size="large"
              className={styles.closeIcon}
              onClick={() => {
                setCode('');
                setCodeId(0);
              }}
              icon={<CloseOutlined />}
            />
          </div>)
            : (
                <div className={styles['client-registration-text']}>
                  <Paragraph className={styles.registrationDate}>
                    Дата окончания: {moment(Point.expiresAt).local().format('DD.MM.YYYY') ?? ''}
                  </Paragraph>
                  <Divider type="vertical" className={styles.dividerContainer} />

                  <Button
                      type="link"
                      icon={<Edit />}
                      size="large"
                      onClick={() => handleEdit(Point.id ?? 0)}
                  />

                  <Divider type="vertical" className={styles.dividerContainer} />

                  <Popconfirm
                    placement="top"
                    title="Удалить точку?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => handleTrash(Point.id ?? 0)}
                  >

                    <Button
                        type="link"
                        icon={<Trash />}
                        size="large"
                    />
                  </Popconfirm>

                  <Divider type="vertical" className={styles.dividerContainer} />

                  {ComponentToRender}

                  <Divider type="vertical" className={styles.dividerContainer} />

                  {Point.hasCode
                    ? (<Popconfirm
                      placement="top"
                      title="Старый код будет деактивирован!"
                      description="Продолжить?"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => getCodeHandler(Point.id ?? 0, Point)}
                    >

                      <Button
                        className={styles.newCodeButton}
                        size="large"
                      >
                        Новый код
                      </Button>
                       </Popconfirm>)

                    : (<Button
                        className={styles.newCodeButton}
                        size="large"
                        onClick={() => getCodeHandler(Point.id ?? 0, Point)}
                      >
                        Сгенерировать код
                       </Button>)}
                </div>
            )}
          </div>
        </div>
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, code, filterInput, loadingSwitch, playList]);

  const pointsCount = useMemo(() => {
    return points.length;
  }, [points.length]);

  return (
    <div className={styles['client-list-container']}>
      <div className={styles['client-list_controls']}>
        <div className={styles.clientTitleContainer}>
          <Button
            type="link"
            icon={<GoBack />}
            size="middle"
            onClick={goToClients}
            className={styles.goBackButton}
          />

          <Paragraph className={styles.clientsTitle}>
            {'Клиент: '}
            <span>{companyName}</span>
          </Paragraph>
        </div>

        <Button
          type="primary"
          className={styles.addClientButton}
          size="large"
          onClick={handleAdd}
        >
          Добавить точку
        </Button>
      </div>

      <div className={styles['client-list_count']}>
        <Paragraph className={styles['client-list_count-text']}>Точки: </Paragraph>
        {pointsCount}
      </div>

      <Input
        placeholder="Поиск по наименованию"
        value={filterInput}
        onChange={(e) => setFilterInput(e.target.value)}
        className={styles.searchBar}
      />

      {Clients}
    </div>
  );
};

export default ClientList;
