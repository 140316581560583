/* eslint-disable no-inline-styles/no-inline-styles */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from 'antd';
import { ReactComponent as StudioZv } from 'src/assets/logo.svg';
import { ReactComponent as Logout } from 'src/assets/logout.svg';

import { useAppDispatch } from 'src/store/store';
import { pointsSliceActions } from 'src/store/pointsSlice/pointsSlice.reducer';

import helpers from 'src/utils/helpers';

import COLOR from 'src/styles/colors';

import './header.css';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const resetToken = () => {
    helpers.logOut();
  };

  const { Paragraph } = Typography;

  const goToClients = () => {
    dispatch(pointsSliceActions.setCurrentId(0));
    navigate('/');
  };

  return (
    <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80px',
          backgroundColor: '#FFFFFF',
          padding: '0 40px',
        }}
      >
        <div className="clients-content_container" onClick={goToClients}>
          <StudioZv />

          <Paragraph
            style={{
              marginLeft: '10px',
              fontWeight: 'bold',
              fontSize: '24px',
              textAlign: 'center',
              color: '#1A546D',
              marginBottom: 0,
            }}
          >
            STUDIOZVUKA
          </Paragraph>
        </div>

        <Button
          size="large"
          style={{
            width: '150px',
            borderRadius: '2px',
            background: COLOR.BTN_GRAY,
          }}
          onClick={resetToken}
        >
          <div className="logout-content_container">
            <Logout />
            Выход
          </div>
        </Button>
    </div>
  );
};

export default Header;
