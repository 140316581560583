import { createAsyncThunk } from '@reduxjs/toolkit';

import type { IUser } from 'src/types';
import storage from 'src/utils/storage';
import authApi from 'src/api/authApi';
import helpers from 'src/utils/helpers';

const authorize = createAsyncThunk<IUser | null>(
  'main/authorizeUser',
  async () => {
    const storedAccessToken = storage.authToken.get();
    if (!storedAccessToken) {
      return null;
    }
    try {
      const user = await authApi.getMe();
      return user.data;
    } catch {
      helpers.logOut();
      return null;
    }
  },
);

export default {
  authorize,
};
