/* eslint-disable max-len */
import type { ICompaniesResponse, ICompany } from 'src/types';
import http from './http';

import type { ResponseType } from './http';

const createCompanies = async (data: ICompany) => {
  const response = await http.post('/companies', data);
  return response;
};
// will be refactored, now data.name required
const getCompanies = async (params?: { name?: string; page: number; perPage: number }) => {
  const response = await http.get<ResponseType<ICompaniesResponse>>('/companies', { params });
  return response;
};

const getCompany = async (id: number) => {
  const response = await http.get<ResponseType<ICompany>>(`/companies/${id}`);
  return response;
};

const updateCompany = async (data: { name: string }, id: number) => {
  const response = await http.patch<ResponseType<ICompany>>(`/companies/${id}`, data);
  return response;
};

const deleteCompany = async (id: number) => {
  const response = await http.delete(`/companies/${id}`);
  return response;
};

export default {
  createCompanies,
  getCompanies,
  getCompany,
  deleteCompany,
  updateCompany,
};
