/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { message } from 'antd';

import Header from 'src/components/Header/Header';
import ClientList from './components/ClientList/ClientList';
import AddEditForm from './components/AddEditForm/AddEditForm';
import Loader from 'src/components/Loader/Loader';

import companyApi from 'src/api/companyApi';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { pointsSliceActions } from 'src/store/pointsSlice/pointsSlice.reducer';
import { toastMessages } from 'src/utils/constants';
import { checkError } from 'src/utils/checkError';
import { collator } from 'src/utils/dataHelper';

import styles from './points.module.scss';
import companyPointApi from 'src/api/companyPointApi';

const Client: React.FC = () => {
  const {
    currentId,
    modeType,
    isShowAddForm,
  } = useAppSelector((state) => state.points);

  const { id } = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (currentId || isShowAddForm) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [currentId, isShowAddForm]);

  const errorItem = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.pointAlreadyExists,
    });
  };

  const error = () => {
    messageApi.open({
      type: 'error',
      content: toastMessages.error.somethingWentWrong,
    });
  };

  const errorNoPoint = async () => {
    const isCompanyExist = await hasCompany();
    if (!isCompanyExist) {
      messageApi.open({
        type: 'error',
        content: toastMessages.error.noCompany,
      });
      setTimeout(() => {
        navigate('/');
      }, 1500);
    } else {
      messageApi.open({
        type: 'error',
        content: toastMessages.error.noPoint,
      });
      loadPoints();
    }
  };

  const hasCompany = async () => {
    try {
      const response = await companyApi.getCompany(Number(id) ?? 0);
      if (response.data?.id) {
        return true;
      }
      return false;
    } catch (err) {
      console.error('load error', err);
    }
  };

  const checkedError = (err: any) => {
    const isBadGatewayError = checkError.isBadGatewayError(err);
    if (isBadGatewayError) {
      error();
    } else if (
      err?.code === 'ERR_BAD_REQUEST' &&
      (err?.response?.data?.message?.includes('already exists') || err?.response?.data?.message?.includes('already in use'))
    ) {
      errorItem();
    } else if (err?.code === 'ERR_BAD_REQUEST' && err?.response?.data?.message?.includes('not find user')) {
      errorNoPoint();
    } else if (err?.response?.data?.message?.includes('not find company')) {
      messageApi.open({
        type: 'error',
        content: toastMessages.error.noCompany,
      });
      setTimeout(() => {
        navigate('/');
      }, 1500);
    }
  };

  useEffect(() => {
    loadPoints();
    loadPlaylist();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useAppDispatch();

  const loadPoints = async () => {
    try {
      setIsLoading(true);
      const response = await companyApi.getCompany(Number(id));

      const sortedPoints = response.data.users?.sort((a, b) => collator.compare(a.name, b.name));

      if (response.data && sortedPoints) {
        dispatch(pointsSliceActions.setCompanyName(response.data.name));
        dispatch(pointsSliceActions.setPoints(sortedPoints));
      }
    } catch (err) {
      console.error('load error', err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadPlaylist = async () => {
    try {
      const response = await companyPointApi.getPlaylists();
      dispatch(pointsSliceActions.setPlayList(response.data));
    } catch (err) {
      console.warn('load playlist error', err);
    }
  };

  const handleCloseForm = () => {
    dispatch(pointsSliceActions.setCurrentId(0));
    dispatch(pointsSliceActions.setModeType('add'));
    dispatch(pointsSliceActions.setIsShowAddForm(false));
  };

  return (
    <>
      <div className={styles['clients-layout-container']}>
        <Header />

        <ClientList onErr={checkedError} />
      </div>
      {currentId || isShowAddForm
        ? (<AddEditForm
            id={currentId}
            type={modeType}
            cb={handleCloseForm}
            onErr={checkedError}
          />)
        : null}

      {isLoading && <Loader />}
      {contextHolder}
    </>
  );
};

export default Client;
